const HolyClipAppConst = {
  RECORD_HEIGHT: 170,
  MP4_ENDPOINT: "/mp4/",
  GIF_ENDPOINT: "/gif/",
  // API_ENDPOINT:"https://17fl6vznr1.execute-api.us-east-1.amazonaws.com/debug", //
  API_ENDPOINT:"https://cdn.holyclip.net/v1",
  // CV_ENDPOINT:"https://watson-api-explorer.ng.bluemix.net/visual-recognition/api/v3/classify?version=2018-03-19",
  // FEATURES_ENDPOINT: "//localhost:4000/features"
  EPISODES: [{
    label: "All Episodes",
    value: -1
  }],
  SERIES: [{
    label: "All Series",
    value: "All"
  },
  {
    label: "Airplane!",
    value: "Airplane"
  },
  {
    label: "Curb Your Enthusiasm",
    value: "Curb",
    hasEpisodes: true
  },
  {
    label: "Seinfeld",
    value: "Seinfeld",
    hasEpisodes: true
  },
  {
    label: "The Big Lebowski",
    value: "Lebowski"
  },
  {
    label: "Twin Peaks",
    value: "Twin Peaks",
    hasEpisodes: true
  }],
  getSeriesMap: function () {
    // Keep index of series info constants
    let seriesInfo = [];
    HolyClipAppConst.SERIES.forEach(x => {
      seriesInfo[x.value] = x;
    });
    return seriesInfo;
  },
  stripHtml: function (str) {
    var tmp;
    if (!window.stripHtmlDiv) {
      window.stripHtmlDiv = document.createElement("DIV");
    }
    tmp = window.stripHtmlDiv;
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }
};

export default HolyClipAppConst;