import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import VideoResult from './VideoResult';
import Slider from './Slider';
import APP_CONST from './HolyclipAppConst'
import RadioButtonGroup from './RadioButtonGroup';
import './GifOptions.css';
import './DetailView.css'

const SHARE_LABEL = "copy";

class DetailView extends Component {

  constructor(props) {
    super(props);
    this.makeDirty = this.makeDirty.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.getApiUrl = this.getApiUrl.bind(this);
    this.captionsChanged = this.captionsChanged.bind(this);
    this.setStateFromURL = this.setStateFromURL.bind(this);
    this.downloadClick = this.downloadClick.bind(this);
    this.shareClick = this.shareClick.bind(this);
    this.startChanged = this.startChanged.bind(this);
    this.endChanged = this.endChanged.bind(this);
    this.customTextChanged = this.customTextChanged.bind(this);
    this.getParams = this.getParams.bind(this);
    this.sendClicked = this.sendClicked.bind(this);
    this.shareClicked = this.shareClicked.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.cropChanged = this.cropChanged.bind(this);
    this.dialogContainerRef = React.createRef();
    let state = {
      showMessage: SHARE_LABEL,
      captions: "on",
      customText: "this clip",
      dirty: false,
      crop: "none",
      prevText: "previous clip",
      nextText: "next clip"
    }
    if (props && props.location && props.location.state) {
      state.caption = props.location.state.caption;
      state.showShareVideo = false;
    }
    if (props && props.match && props.match.params && props.match.params.id) {
      let id = props.match.params.id;
      let params = "";
      if (props.location && props.location.search) {
        params = props.location.search;
      }
      state.url = APP_CONST.API_ENDPOINT + APP_CONST.MP4_ENDPOINT + encodeURIComponent(id) + params
    }
    this.state = state;
    this.seriesInfo = APP_CONST.getSeriesMap();
  }

  shouldComponentUpdate(props, state) {
    // Is current displayed URL different than requested location
    let parsedUrl = new URL(state.url);
    let newId = props.match.params.id;
    let pathTokens = parsedUrl.pathname.split("/");
    let curId = pathTokens[pathTokens.length - 1];
    let newUrl = APP_CONST.API_ENDPOINT + APP_CONST.MP4_ENDPOINT + encodeURI(newId) + props.location.search;
    // Check if any of the parameters are different
    let paramsDiffer = false;
    let params = props.location.search.replace("?", "").split("&");
    params.forEach(function (p) {
      let kvp = p.split("=");
      if (kvp.length === 2) {
        let key = kvp[0];
        let value = decodeURIComponent(kvp[1]);
        let oldValue = parsedUrl.searchParams.get(key);
        if (!oldValue || oldValue !== value) {
          paramsDiffer = true;
        }
      }
    });
    if (newId !== curId || paramsDiffer || parsedUrl.searchParams.length !== params.length) {
      // Img urls differ, update detail url
      if (state.url !== newUrl) {
        state.url = newUrl;
        this.setStateFromURL(newUrl);
      }
    }
    return true;
  }

  componentDidMount() {
    if (this.state.url) {
      this.setStateFromURL(this.state.url);
    }
    if (this.state.caption) {
      this.setState({
        customText: this.state.caption
      });
    }
  }

  setStateFromURL(url) {
    let parsed = new URL(url);
    let pathTokens = parsed.pathname.split("/");
    let videoId = pathTokens[pathTokens.length - 1];
    if (videoId !== this.state.VideoId) {
      // Get clip info and next/prev info
      let script = document.createElement("script");
      const JSONP_ID = "queryById";
      let that = this;
      window[JSONP_ID] = function (results) {
        let newState = {
          customText: APP_CONST.stripHtml(results.Text),
          title: results.Name,
          episodeNumber: results.VideoId,
          seriesId: results.SeriesId
        };
        if (results.prevClip !== undefined) {
          newState.prevText = APP_CONST.stripHtml(results.prevClip.Text);
          newState.prevGuid = results.prevClip.GUID;
          // Calc end offset for next clip and add small amount of padding
          newState.startOffset = parseFloat(Math.min(results.StartSeconds - results.prevClip.StartSeconds, 4.9).toFixed(1));
        }
        if (results.nextClip !== undefined) {
          newState.nextText = APP_CONST.stripHtml(results.nextClip.Text);
          newState.nextGuid = results.nextClip.GUID;
          newState.endOffset = parseFloat(Math.min(results.nextClip.EndSeconds - results.EndSeconds + 0.3, 4.9).toFixed(1));
        }
        if (newState.customText && newState.customText.length > 0) {
          window.document.title = newState.customText;
        }
        newState.crop = "none";
        that.setState(newState);
      }
      script.src = `${APP_CONST.API_ENDPOINT}/query?id=${videoId}&callback=${encodeURIComponent(JSONP_ID)}`;
      script.onerror = function () {
        window.location.replace("/404.html");
        // console.log(JSON.stringify(arguments));
      };
      document.body.appendChild(script);
    }
    let customTextUrl = parsed.searchParams.get("customText");
    let newState = {
      VideoId: videoId,
      clipStart: parsed.searchParams.get("start"),
      clipEnd: parsed.searchParams.get("end"),
      captions: parsed.searchParams.get("captions") || "on",
    };
    if (customTextUrl) {
      newState.customText = customTextUrl;
    }
    setTimeout(() => {
      this.setState(newState);
    },100);
  }

  makeDirty(dirty) {
    if (dirty === undefined) dirty = true;
    this.setState({
      dirty: dirty,
      showMessage: SHARE_LABEL
    });
  }

  customTextChanged(e) {
    this.setState({
      customText: e.target.value
    });
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.makeDirty();
    }, 500);
  }

  startChanged(e) {
    this.setState({
      clipStart: e.target.value
    });
    this.makeDirty();
  }

  endChanged(e) {
    this.setState({
      clipEnd: e.target.value
    });
    this.makeDirty();
  }

  cropChanged(e, selected) {
    // compute start or end time based on crop
    let start, end;
    switch (selected) {
      case "prev":
        start = this.state.startOffset;
        break;
      case "next":
        end = this.state.endOffset;
        break;
      case "full":
        start = this.state.startOffset;
        end = this.state.endOffset;
        break;
      case "custom":
        start = this.state.clipStart !== undefined ? this.state.clipStart : undefined;
        end = this.state.clipEnd !== undefined ? this.state.clipEnd : undefined;
        break;
      case "none":
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.setState({
        clipStart: start,
        clipEnd: end,
        crop: selected
      });
      this.makeDirty();
    }, 100);

  }

  captionsChanged(e, selected) {
    if (selected) {
      let newState = {
        captions: selected,
      };
      this.setState(newState);
      this.makeDirty();
    }
  }

  getParams() {
    // Create route url based on current options
    let createUrlParam = function (name, value) {
      return [name, encodeURIComponent(value)].join("=");
    };
    let params = [];
    let options = this.state;
    if (options.clipStart) params.push(createUrlParam("start", options.clipStart));
    if (options.clipEnd) params.push(createUrlParam("end", options.clipEnd));
    if (options.captions && options.captions !== "on") params.push(createUrlParam("captions", options.captions));
    if (options.captions === "custom" && options.customText) {
      params.push(createUrlParam("customText", options.customText));
    }
    return params;
  }

  getUrl() {
    return this.getApiUrl("share");
  }

  getApiUrl(method, addDlParam) {
    let params = this.getParams();
    let longUrl = `${APP_CONST.API_ENDPOINT}/${method}/${this.state.VideoId}`;
    if (addDlParam) {
      params.push("dl=1");
    }
    if (method === "share" && this.state.customText && this.state.customText.length > 0) {
      // Set title for social media use
      params.push(`title=${encodeURIComponent(this.state.customText)}`);
    }
    if (params.length > 0) {
      longUrl += "?" + params.join("&");
    }
    return longUrl;
  }

  downloadClick(type) {
    const url = this.getApiUrl(type, true);
    if (window.gtag) {
      // Google Analytics track download
      window.gtag("event", "file_download", {
        format: type,
        clip: this.state.VideoId,
        url: window.location.href
      });
    }
    window.location = url;
  }

  shareClick() {
    try {
      // Copy url to clipboard using hidden input
      let input = document.createElement("input");
      input.value = window.location;
      input.readOnly = true;
      document.body.appendChild(input);
      input.focus();
      input.setSelectionRange(0, input.value.length);
      document.execCommand("copy");
      document.body.removeChild(input);

      this.setState({
        showMessage: "copied url"
      });

      if (typeof (this.props.onShareClick) === "function") {
        this.props.onShareClick();
      }

      this.shareClicked();

      if (window.gtag) {
        // Google Analytics track generic share event
        window.gtag("event", "share", {
          clip: this.state.VideoId,
          url: window.location.href
        });
      }
    }
    catch (e) {
      console.log("unable to copy url " + e);
    }
  }

  sendClicked() {
    let url = `holyclip:${this.state.VideoId}&` + this.getParams().join("&");
    window.location.assign(url);
    this.setState({
      loading: true
    });
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 10000);
  }

  createUrlParam(name, value) {
    return [name, encodeURIComponent(value)].join("=");
  }

  shareClicked() {
  }

  closeDialog(e) {
    // Close dialog if click parent dialog container and not child element within dialog
    if (e && e.target && e.target === this.dialogContainerRef.current && this.props.history) this.props.history.push("/");
  }

  render() {
    let isDirty = this.state.dirty;
    if (isDirty) {
      setTimeout(() => {
        this.makeDirty(false);
      });
    }
    let isAppleApp = window.navigator.userAgent === "spaz.HolyclipApp.MessagesExtension";
    let seriesInfo = this.seriesInfo[this.state.seriesId];
    if (this.notFound === true) window.location.replace("/404.html");
    return (
      <div className="spaz-image-view">
        <section ref={this.dialogContainerRef} onClick={this.closeDialog} role="dialog" aria-label="" aria-modal="true" aria-describedby="modal-content-id-1" className={this.state.VideoId ? "slds-modal slds-slide-up-saving" :"slds-modal"}>
          <div className="slds-modal__container">
            <header className="slds-modal__header">
              <h2 className="slds-text-heading_small" id="prompt-heading-id">
                {
                seriesInfo !== undefined ?
                  (seriesInfo.hasEpisodes === true ?
                    <React.Fragment>
                      <Link to={`/series/${this.state.seriesId}`}>
                        {this.state.seriesId}
                      </Link>
                      &nbsp;-&nbsp;
                      <Link to={`/series/${this.state.seriesId}/${this.state.episodeNumber}`}>
                        {this.state.title}
                        <svg className="slds-button slds-button__icon slds-m-bottom_xx-small" aria-hidden="true">
                          <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronright" />
                        </svg>
                      </Link>
                    </React.Fragment>
                    :
                    <Link to={`/series/${this.state.seriesId}`}>
                      {this.state.seriesId}
                    </Link>
                  )
                  : "Series"}
              </h2>
              <Link to="/" className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse">
                <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#close" />
                </svg>
                <span className="slds-assistive-text">Close</span>
              </Link>
              <Link to={`/clip/${this.state.prevGuid}`} className="spaz-nav-left">
                <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronleft" />
                </svg>
                <span className="slds-assistive-text">Prev</span>
              </Link>
              <Link to={`/clip/${this.state.nextGuid}`} className="spaz-nav-right">
                <svg className="slds-button__icon slds-button__icon_large" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#chevronright" />
                </svg>
                <span className="slds-assistive-text">Next</span>
              </Link>
            </header>
            <div className="spaz-holyclip-detail slds-modal__content" id="modal-content-id-1">
              <VideoResult url={this.state.url} title={this.state.caption} />
            </div>
            <footer className="slds-modal__footer">
              <div className="spaz-gif-options slds-grid slds-wrap slds-grid_vertical-align-end">
                {/* If rendering with dirty state push url changes and rerender with new options */}
                {isDirty ?
                  <Redirect push to={`/clip/${this.state.VideoId}?` + this.getParams().join("&")} />
                  :
                  ""
                }
                <div className="slds-grid slds-col slds-grid_align-center slds-size_1-of-1 slds-p-horizontal_x-small slds-p-top_x-small slds-text-body_small slds-text-color_weak">
                  <div className="slds-col slds-size_1-of-3">
                    {this.state.prevText && this.state.prevText.length > 0 ?
                      <Link to={`/clip/${this.state.prevGuid}`}>
                        {this.state.prevText}
                      </Link>
                      : ""}
                  </div>
                  <div className="slds-col slds-size_1-of-3 slds-p-horizontal_xx-small">{this.state.customText}</div>
                  <div className="slds-col slds-size_1-of-3">
                    {this.state.nextText && this.state.nextText.length > 0 ?
                      <Link to={`/clip/${this.state.nextGuid}`}>{this.state.nextText}
                      </Link>
                      : ""}
                  </div>
                </div>
                <div className={this.state.crop !== "custom" ? "spaz-hidden" : "slds-grid slds-col slds-grid_align-center slds-size_1-of-1 slds-p-horizontal_x-small"}>
                  <Slider className="slds-col slds-size_1-of-3 spaz-left-slider" default={this.state.clipStart} min={0} max={4.9} step={0.1} onChange={this.startChanged}>
                  </Slider>
                  <span className="slds-col slds-size_1-of-3">
                    <Slider disabled={true} default={this.state.clipEnd} min={0} max={4} step={0.1}>
                    </Slider>
                  </span>
                  <Slider className="slds-col slds-size_1-of-3" default={this.state.clipEnd} min={0} max={4.9} step={0.1} onChange={this.endChanged}>
                  </Slider>
                </div>
                <div className="slds-col slds-p-horizontal_x-small slds-size_1-of-1 slds-medium-size_1-of-2">
                  <RadioButtonGroup onChange={this.cropChanged} label="extend" selected={this.state.crop} buttons={[
                    {
                      label: "prev",
                      iconName: "arrow_left",
                      value: "prev"
                    },
                    {
                      label: "none",
                      iconName: "diamond",
                      value: "none"
                    },
                    {
                      label: "next",
                      iconName: "arrow_right",
                      value: "next"
                    },
                    {
                      label: "full",
                      iconName: "product_transfer",
                      value: "full"
                    },
                    {
                      label: "custom",
                      iconName: "custom_apps",
                      value: "custom"
                    }
                  ]} />

                </div>
                <div className="slds-col slds-p-horizontal_x-small slds-size_1-of-1 slds-medium-size_1-of-2">
                  <RadioButtonGroup onChange={this.captionsChanged} label="caption" selected={this.state.captions} buttons={[
                    {
                      label: "on",
                      value: "on",
                    },
                    {
                      label: "off",
                      value: "off",
                    },
                    {
                      label: "meme",
                      value: "custom",
                      iconName: "custom_apps"
                    }
                  ]} />

                </div>
                {this.state.captions === "custom" ?
                  <div className="slds-col slds-size_1-of-1 slds-p-around_x-small">
                    <input value={this.state.customText} onChange={this.customTextChanged} type="text" />
                  </div>
                  : ""
                }

                {isAppleApp ?
                  <div className="slds-col_bump-left slds-col slds-size_1-of-4 slds-grid slds-grid_vertical slds-m-right_x-small slds-m-bottom_x-small">
                    {this.state.loading ?
                      <span className="spaz-spinner slds-m-horizontal_medium"></span>
                      :
                      <button onClick={this.sendClicked} className="slds-button slds-button_brand">Send</button>
                    }
                  </div>
                  :
                  <React.Fragment>
                    <div className="slds-form-element slds-p-around_x-small slds-p-top_none slds-size_1-of-1 slds-medium-size_1-of-2">
                      <legend className="slds-form-element__legend slds-form-element__label">share</legend>
                      <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
                        <input type="text" id="text-input-id-46" onChange={() => { }} value={window.location} className="slds-input" />
                        <button className="slds-button slds-button_icon slds-input__icon slds-input__icon_right" title={SHARE_LABEL} onClick={this.shareClick}>
                          <svg className="slds-button__icon slds-icon-text-light" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#layers"></use>
                          </svg>
                          <span className="slds-assistive-text">Copy</span>
                        </button>
                      </div>
                    </div>
                    <div className="slds-col slds-align-bottom slds-m-horizontal_x-small slds-m-bottom_x-small slds-text-body_small slds-text-color_weak">
                      <React.Fragment>
                        <button onClick={() => this.downloadClick("gif")} title="Download Gif" className="slds-button slds-button_icon slds-p-right_xx-small">
                          <svg className="slds-button__icon slds-button__icon_left slds-m-bottom_x-small" aria-hidden="true">
                            <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#download" />
                          </svg>
                          gif</button>
                        <button onClick={() => this.downloadClick("mp4")} title="Download Mp4" className="slds-button slds-button_icon">mp4</button>
                      </React.Fragment>
                    </div>
                  </React.Fragment>

                }
              </div >
            </footer>
          </div>
        </section >
        <div className={this.state.VideoId ?  "slds-backdrop slds-backdrop_open" : "slds-backdrop"}></div>
      </div >
    )
  }
}

export default withRouter(DetailView);