import React, { Component } from 'react';
import './ImageResult.css'

class ImageResult extends Component {

  render() {
    return (
      <span className="spaz-gif">
        <div className="spinner"></div>
        <img alt={this.props.title} title={this.props.title} src={this.props.url} style={{visibility: this.props.loading ? 'hidden' : 'visible' }} />
        <div className="spaz-caption">{this.props.title}</div>
        <button className="spaz-button-share slds-button slds-button_icon">
          <svg className="slds-button__icon" aria-hidden="true">
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#share_mobile" />
          </svg>
          <span className="slds-assistive-text">Share</span>
        </button>
      </span>
    )
  }
}

export default ImageResult;