import React, { Component } from 'react';
import { Route } from 'react-router-dom'
import APP_CONST from './HolyclipAppConst'
import SearchFilter from './SearchFilter';
import './SearchInput.css';

class SearchInput extends Component {


  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isLoading: false,
      hasText: false,
      selected: APP_CONST.SERIES[0]
    };
    this.search = this.search.bind(this);
    this.clearText = this.clearText.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.queryEpisodes = this.queryEpisodes.bind(this);
    this.selectedChanged = this.selectedChanged.bind(this);
    this.seriesInfo = APP_CONST.getSeriesMap();
  }

  queryEpisodes(seriesId) {
    let script = document.createElement("script");
    const JSONP_ID = "queryBySeriesId";
    let that = this;
    window[JSONP_ID] = function (results) {
      let episodes = [{
        label: APP_CONST.EPISODES[0].label,
        value: that.state.seriesId
      }];
      let selected = episodes[0];
      for (var i = 0; i < results.length; i++) {
        let videoId = parseInt(results[i].Id, 10);
        let listItem = {
          label: results[i].Name,
          seriesId: that.state.seriesId,
          value: videoId
        };
        episodes.push(listItem);
        if (that.state.selected && that.state.selected.value === videoId) {
          selected = listItem;
        }
      }
      that.setState({
        episodes: episodes,
        isFilterLoading: false
      });
      that.selectedChanged(selected.value);
    }
    script.src = `${APP_CONST.API_ENDPOINT}/query?seriesId=${encodeURIComponent(seriesId)}&callback=${encodeURIComponent(JSONP_ID)}`;
    script.onerror = function () {
      console.log(JSON.stringify(arguments));
    };
    document.body.appendChild(script);
  }

  selectedChanged(id) {
    if (typeof (this.props.onFilterChanged) === "function") {
      let filterValue = {};
      if (typeof (id) === "number" && this.state.episodes) {
        let newState = {};
        // Use episode as selected item so that label is displayed
        this.state.episodes.forEach(x => {
          if (x.value === id) {
            newState.selected = x;
          }
        });
        this.setState(newState);
        filterValue.videoId = id;
      }
      else {
        filterValue.seriesId = id;
      }
      this.props.onFilterChanged(filterValue);
    }
  }

  filterSearch(item) {
    item = item || APP_CONST.SERIES[0];
    // If item is specific video then value is videoId
    let seriesId = item && (item.seriesId || item.value);
    if (seriesId !== this.state.seriesId || item.value !== this.state.selected.value) {
      let newState = {
        seriesId: seriesId,
        selected: item
      };
      if (seriesId !== this.state.seriesId) {
        if (item.hasEpisodes) {
          // Changing series to series with episodes
          newState.episodes = APP_CONST.EPISODES;
          newState.isFilterLoading = true;
          this.queryEpisodes(seriesId);
        }
        else {
          newState.episodes = undefined;
          this.selectedChanged(seriesId);
        }
        this.setState(newState);
      }
      else {
        this.selectedChanged(item.value);
      }
      if (this.state.hasText) {
        this.setState(newState);
        // Rerun search query if input has text
        this.search({
          target: this.inputRef.current
        });
      }
    }
  }

  search(e) {
    let that = this;
    let value = e.target.value;

    const JSONP_ID = "SearchInputJSONP";
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
      // Expire any old search callback to use empty function (discard results);
      window[JSONP_ID + this.debounceTimer.toString()] = function () { };
    }
    if (value.length > 2) {
      this.debounceTimer = setTimeout(function () {
        if (!that.state.hasText) {
          if (value.length > 0) {
            that.setState({
              hasText: true
            });
          }
          else {
            that.setState({
              hasText: false
            });
          }
        }
        // JSONP global handler for search results
        window[JSONP_ID + that.debounceTimer.toString()] = function (results) {
          that.setState({
            isLoading: false
          });
          if (typeof (that.props.onSearchResults) === "function") {
            that.props.onSearchResults(results, value);
          }
          // Track search with google analyics
          if (window.gtag && results && results.length !== undefined) {
            window.gtag("event", "view_search_results", {
              query: value,
              results: results.length
            });
          }
        };
        let script = document.createElement("script");
        script.src = `${APP_CONST.API_ENDPOINT}/query?text=${encodeURIComponent(value)}&callback=${encodeURIComponent(JSONP_ID + that.debounceTimer.toString())}`;
        if (that.state.selected && typeof (that.state.selected.value) === "number") {
          script.src += `&videoId=${encodeURIComponent(that.state.selected.value)}`;
        }
        else if (that.state.seriesId && that.state.seriesId.length > 0 && that.state.seriesId !== APP_CONST.SERIES[0].value) {
          script.src += `&seriesId=${encodeURIComponent(that.state.seriesId)}`;
        }

        script.onerror = function (err) {
          that.setState({
            isLoading: false
          });
        };
        document.body.appendChild(script);
        that.setState({
          isLoading: true
        });
      }, 300);
    }
  }

  clearText() {
    this.inputRef.current.value = "";
    this.setState({
      hasText: false
    });
    if (typeof (this.props.onClear) === "function") {
      this.props.onClear();
    }
  }

  render() {
    return (
      <div className="spaz-search-input slds-form-element slds-p-bottom_xx-small">
        <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
          <div className="slds-combobox-group">
            <Route path={`${this.props.match.url}series/:seriesId/:videoId?`} component={(props) => {
              if (props && props.match && props.match.params && props.match.params.seriesId) {
                let series = Object.assign({}, this.seriesInfo[props.match.params.seriesId]);
                if (props.match.params.videoId) {
                  series.seriesId = props.match.params.seriesId;
                  series.value = parseInt(props.match.params.videoId, 10);
                }
                this.filterSearch(series);
              }
              return null;
            }} />
            <SearchFilter isLoading={this.state.isFilterLoading} onResetClick={this.filterSearch} listItems={this.state.episodes ? this.state.episodes : APP_CONST.SERIES} selected={this.state.selected} />
            {this.state.hasText ? (
              <button onClick={this.clearText} className="slds-input__icon slds-input__icon_right slds-button slds-button_icon">
                <svg className="slds-button__icon slds-icon-text-light" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear" />
                </svg>
                <span className="slds-assistive-text">Clear</span>
              </button>
            ) : (
                <svg className="slds-icon slds-input__icon slds-input__icon_right slds-icon-text-default" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#search" />
                </svg>
              )}
            <div className="slds-combobox_container slds-combobox-addon_end">
              <input ref={this.inputRef} type="text" onChange={this.search} className="slds-input slds-combobox__input" placeholder={`Search ${this.state.seriesId && this.state.seriesId !== APP_CONST.SERIES[0].value ? this.state.seriesId + " " : ""}Dialogue`} />
            </div>
            {this.state.isLoading && (
              <div className="slds-input__icon-group slds-input__icon-group_right">
                <div role="status" className="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner">
                  <span className="slds-assistive-text">Loading</span>
                  <div className="slds-spinner__dot-a"></div>
                  <div className="slds-spinner__dot-b"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default SearchInput;