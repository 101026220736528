import React, { Component } from 'react';
import APP_CONST from './HolyclipAppConst'
import ResultList from './ResultList';
import './ResultList.css';

class PopularList extends Component {

  constructor(props) {
    super(props);
    this.getJSONPData = this.getJSONPData.bind(this);
    this.state = {
    };
  }

  getJSONPData() {
    const that = this;
    let script = document.createElement("script");
    const JSONP_ID = "popularCallback";
    let requestId = Math.random().toString(16);
    this.requestId = requestId;
    this.setState({
      results: undefined
    });
    window[JSONP_ID] = function (results) {
      if (that.requestId === requestId) {
        let randomResults = [];
        let resultsCopy = results.slice(0);
        const NUM_RESULTS = 60; // 60 divisible by 1,2,3,4,5,6 to responisvely fill columns
        for (let i = 0; i < NUM_RESULTS; ++i) {
          let j = parseInt(Math.random() * resultsCopy.length, 10);
          let r = resultsCopy[j];
          resultsCopy = resultsCopy.slice(0, j).concat(resultsCopy.slice(j + 1, resultsCopy.length));
          randomResults.push(r);
        }
        that.setState({
          results: randomResults
        });
        if (typeof (that.props.onload) === "function") {
          // Trigger virtual scroll login update
          that.props.onload();
        }
      }
    }
    let url = APP_CONST.API_ENDPOINT + "/popular?callback=" + encodeURIComponent(JSONP_ID);
    if (this.props.videoId !== undefined) {
      url += "&videoId=" + encodeURIComponent(this.props.videoId);
    }
    if (this.props.seriesId !== undefined && this.props.seriesId !== APP_CONST.SERIES[0].value) {
      url += "&seriesId=" + encodeURIComponent(this.props.seriesId);
    }
    script.src = url;
    script.onerror = function (err) {
      console.log(JSON.stringify(arguments));
    };
    document.body.appendChild(script);
  }

  debounceDataLoad() {
    clearTimeout(this.loadTimer);
    this.loadTimer = setTimeout(this.getJSONPData, 100);
  }

  componentDidMount() {
    this.debounceDataLoad();
  }

  componentDidUpdate(prevProps) {
    let reload = this.props.seriesId !== prevProps.seriesId || this.props.videoId !== prevProps.videoId;
    if (reload) {
      this.debounceDataLoad();
    }
  }

  render() {
    let results = [];
    if (this.state.results && this.state.results.length > 0) {
      results = this.state.results;
    }
    return (
      <div className="spaz-result-list spaz-scroll slds-p-bottom_xx-large" onScroll={this.onscroll}>
        <div className="slds-text-title_caps">Most Popular</div>
        <ResultList ref={this.props.refPassThru} results={results} />
      </div>
    )
  }
}

export default PopularList;