import React, { Component } from 'react';

class Slider extends Component {

  constructor(props) {
    super(props);
    this.range = React.createRef();
    this.changed = this.changed.bind(this);
    this.onInput = this.onInput.bind(this);
    this.state = {
      value: 0
    }
  }

  changed(e) {
    clearTimeout(this.timer);
    e.persist();
    let that = this;
    that.setState({
      value: e.target.value,
      dragging: true
    });
    this.timer = setTimeout(function () {
      if (typeof (that.props.onChange) === "function") {
        that.props.onChange(e);
        that.setState({
          dragging: false
        });
      }
    }, 300);
  }

  createId() {
    const length = 100000;
    return parseInt((Math.random() * length) + length, 10).toString(16);
  }


  componentDidUpdate(prevProps) {
    this.onInput();
    // Set default slider value to default
    if (this.range && this.range.current && this.props.default !== prevProps.default && typeof (this.props.default) === "number" && !Number.isNaN(this.props.default)) {
      this.range.current.value = this.props.default;
      this.setState({
        value: this.props.default
      });
    }
  }

  onInput() {
    let inputEl = this.range.current;
    const COLOR1 = "#195594";
    const COLOR2 = "#ffffff";
    if (inputEl) {
      let isReversed = this.props.className && this.props.className.indexOf("spaz-left-slider") > -1;
      let val = parseFloat(inputEl.value);
      let max = parseFloat(inputEl.max);
      let min = parseFloat(inputEl.min);
      let percent = parseInt((val - min) / (max - min) * 100);
      let background = `linear-gradient(to right, ${COLOR1} 0%, ${COLOR1} ${percent}%, ${COLOR2} ${percent}%, ${COLOR2} 100%)`;
      if (isReversed) {
        percent = 100 - percent;
        background = `linear-gradient(to right, ${COLOR2} 0%, ${COLOR2} ${percent}%, ${COLOR1} ${percent}%, ${COLOR1} 100%)`;
      }
      inputEl.style.background = background;
    }
  }

  render() {
    const id = this.createId();
    let disabled = this.props.disabled;
    return (
      <div className={(this.props.className || "") + " spaz-slider"}>
        {disabled ?
          <input disabled="disabled" ref={this.range} type="range" id={id} step={this.props.step} min={this.props.min} max={this.props.max} value={this.props.max} />
          :
          <input onInput={this.onInput} ref={this.range} type="range" id={id} step={this.props.step} min={this.props.min} max={this.props.max} onChange={this.changed} />
        }
      </div>
    )
  }
}

export default Slider;