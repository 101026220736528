import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class SearchFilterListItem extends Component {

  render() {
    return (
      <li role="presentation" className={(this.props.selected ? "slds-is-selected " : "") + "slds-listbox__item"}>
        <Link to={this.props.to}>
          <div aria-selected={this.props.selected === true} className="slds-media slds-listbox__option slds-listbox__option_plain slds-media_small" role="option">
            <h3 className="slds-listbox__option-header" role="presentation">{this.props.label}</h3>
          </div>
        </Link>
      </li>
    );
  }

}

export default SearchFilterListItem;