import React, { Component } from 'react';
import { Link } from 'react-router-dom'


class ThankYou extends Component {

  render() {
    return (<div class="spaz-thanks-container">
        <div class="slds-modal__container">
          <div class="spaz-splash slds-size_1-of-1 slds-p-around_large">
            <div class="slds-welcome-mat__info-content">
              <div class="slds-welcome-mat__info-description slds-text-longform">
                <h2 class="slds-welcome-mat__info-title">Spaz</h2>
              </div>
              <p>Thank You for Contacting Us!</p>
              <p>Someone will reach out to you as soon as humanly possible.</p>
              <Link to="/">
                <img src="/spaz.gif" alt="animated spaz logo" height="100%" />
              </Link>
            </div>
          </div>
        </div>
    </div>);
  }

}

export default ThankYou;