import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './index.css';
import About from './About';
import registerServiceWorker from './registerServiceWorker';
import HolyclipApp from './HolyclipApp';
import ThankYou from './ThankYou';

ReactDOM.render(
  <BrowserRouter>
    <div>
      <Route path="/" render={({ location }) => {
        // Run JS on every route navigate (legacy analytics)
        return null;
      }} />
      <Switch>
        <Route path="/about-holyclip" component={About} />
        <Route path="/appstore" component={() => window.location = 'https://apps.apple.com/bs/app/holy-clip/id1438773894'} />
        <Route path="/twitter" component={() => window.location = 'https://twitter.com/holyclip'} />
        <Route path="/thankyou" component={ThankYou} />
        <Route path="/" component={HolyclipApp} />
      </Switch>
    </div>
  </BrowserRouter>, document.getElementById('root'));
registerServiceWorker();
