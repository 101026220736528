import React, { Component } from 'react';
import { withRouter } from "react-router";
import APP_CONST from './HolyclipAppConst';
import SearchFilterListItem from './SearchFilterListItem';

class SearchFilter extends Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.openListItems = this.openListItems.bind(this);
    this.closeListItems = this.closeListItems.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.state = {
      isOpen: false
    };
  }

  openListItems() {
    this.setState({
      isOpen: true
    });
  }

  closeListItems() {
    this.setState({
      isOpen: false
    });
  }

  onBlur(e) {
    if (this.containerRef && this.containerRef.current && this.containerRef.current.contains(e.relatedTarget)) {
      // Clicked subitem start recursively polling until activeElement is not submenu item then close
      setTimeout(() => {
        this.onBlur({
          relatedTarget: document.activeElement
        });
      }, 300);
    }
    else {
      setTimeout(() => {
        this.closeListItems();
      }, 300);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected && this.props.selected && this.props.selected.value !== prevProps.selected.value) {
      this.closeListItems();
    }
  }

  resetFilter() {
    this.props.history.push("/");
    if (typeof (this.props.onResetClick) === "function") {
      this.props.onResetClick();
    }
  }

  render() {

    let selectedLabel = this.props.selected && this.props.selected.label;
    if (this.props.selected && this.props.selected.hasEpisodes) {
      selectedLabel = "All Episodes";
    }
    let listItemElements = [];
    if (this.props.listItems) {
      this.props.listItems.forEach((item, i) => {
        let isSelected = this.props.selected && this.props.selected.value === item.value;
        let ep = parseInt(item.value);
        let linkTo = `/series/${item.value}`;
        if (ep > -1) {
          linkTo = `/series/${item.seriesId}/${item.value}`;
        }
        listItemElements.push(<SearchFilterListItem to={linkTo} selected={isSelected} key={item.value} label={item.label} value={item} />);
      });
    }

    return (
      <div className="slds-combobox_object-switcher slds-combobox-addon_start">
        <div className="slds-form-element">
          <label className="slds-form-element__label slds-assistive-text" htmlFor="spaz-search-filter-combobox">Filter Search by:</label>
          <div className="slds-form-element__control">
            <div ref={this.containerRef} className="slds-combobox_container">
              { this.props.selected && this.props.selected.value !== APP_CONST.SERIES[0].value ?
                <button onClick={this.resetFilter} className="slds-input__icon slds-m-right_medium slds-input__icon_right slds-button slds-button_icon">
                  <svg className="slds-button__icon slds-icon-text-light" aria-hidden="true">
                    <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#clear" />
                  </svg>
                  <span className="slds-assistive-text">Clear</span>
                </button>
                : "" }
              <div className={(this.state.isOpen ? "slds-is-open " : "") + "slds-combobox slds-dropdown-trigger slds-dropdown-trigger_click"} aria-controls="primary-search-combobox-id-2" aria-expanded={this.state.isOpen} aria-haspopup="listbox" role="combobox">
                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                  <input onFocus={this.openListItems} onBlur={this.onBlur} type="text" className="slds-truncate slds-input slds-combobox__input slds-combobox__input-value" id="spaz-search-filter-combobox" aria-controls="spaz-search-filter-listbox" readOnly="readonly" autoComplete="off" placeholder=" " value={selectedLabel} />
                  <span className="slds-icon_container slds-icon-utility-down slds-input__icon slds-input__icon_right">
                    <svg className="slds-icon slds-icon slds-icon_xx-small slds-icon-text-default" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#down"></use>
                    </svg>
                  </span>
                </div>
                <div id="spaz-search-filter-listbox" className="slds-dropdown slds-dropdown_length-10 slds-dropdown_x-small slds-dropdown_left" role="listbox">
                  <ul className="slds-listbox slds-listbox_vertical" role="group" aria-label="Filter Search by">
                    {listItemElements}
                    {this.props.isLoading && (
                      <div role="status" className="slds-spinner slds-spinner_brand slds-spinner_x-small slds-input__spinner">
                        <span className="slds-assistive-text">Loading</span>
                        <div className="slds-spinner__dot-a"></div>
                        <div className="slds-spinner__dot-b"></div>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>);
  }

}

export default withRouter(SearchFilter);