import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom'
import './HolyclipApp.css'
import APP_CONST from './HolyclipAppConst'
import SearchInput from './SearchInput';
import ResultList from './ResultList';
import DetailView from './DetailView';
import PopularList from './PopularList';

class HolyclipApp extends Component {

  constructor(props) {
    super(props);
    this.resultsRef = React.createRef();
    this.scrollableRef = React.createRef();
    this.parentRef = React.createRef();

    this.searchResults = this.searchResults.bind(this);
    this.searchResultsCleared = this.searchResultsCleared.bind(this);
    this.changedSearchFilter = this.changedSearchFilter.bind(this);
    this.onscroll = this.onscroll.bind(this);
    this.getRows = this.getRows.bind(this);
    this.state = {
      searchText: "",
      results: undefined
    };
  }

  componentDidMount() {
    if (this.scrollableRef && this.scrollableRef.current) {
      // Allow touch scrolling within scrollable results only
      function preventPropagation(e) {
        e.stopPropagation();
      };
      this.scrollableRef.current.removeEventListener("touchmove", preventPropagation, { passive: false });
      this.scrollableRef.current.addEventListener("touchmove", preventPropagation, { passive: false });
    }
  }


  getRows() {
    let rows = 0;
    if (this.scrollableRef.current) {
      let h = this.scrollableRef.current.clientHeight;
      rows = parseInt(Math.floor(h / APP_CONST.RECORD_HEIGHT), 10);
    }
    return rows;
  }

  onscroll(e) {
    clearTimeout(this.timer);
    if (this.resultsRef && this.resultsRef.current && typeof (this.resultsRef.current.onscroll) === "function") {
      if (this.props && this.props.location && typeof(this.props.location.pathname) === "string" && this.props.location.pathname.indexOf("/clip/") === -1) {
        // Only render clips on page load when not viewing clip for load time performance
        this.resultsRef.current.onscroll(this.scrollableRef.current.scrollTop, this.getRows());
      }
      else {
        this.timer = setTimeout(this.onscroll, 100);
      }
    }
  }

  searchResults(results, value) {
    this.setState({
      results: []
    });
    this.setState({
      results: results,
      searchText: value
    });
    // Fixes bug where results may not load until user scrolls
    this.onscroll();
  }

  searchResultsCleared() {
    this.setState({
      results: undefined
    });
  }

  changedSearchFilter(filter) {
    let newState = {};
    if (filter.videoId !== undefined) {
      newState.videoId = filter.videoId;
      newState.seriesId = undefined;
    }
    else if (filter.seriesId !== undefined) {
      newState.seriesId = filter.seriesId;
      newState.videoId = undefined;
    }
    else {
      newState.seriesId = undefined;
      newState.videoId = undefined;
    }
    if (newState.seriesId !== this.state.seriesId || newState.videoId !== this.state.videoId) {
      this.setState(newState);
    }
  }

  render() {
    let { match } = this.props;
    // let isAppleApp = window.navigator.userAgent === "spaz.HolyclipApp.MessagesExtension";
    return (
      <div className="spaz-holyclip-app">
        <div ref={this.parentRef} className="slds-grid slds-grid_vertical slds-grid_frame">
          <div className="slds-col slds-grow-none slds-grid slds-grid_align-center">
            <div className="spaz-search-col slds-col slds-p-horizontal_medium">
              <div className="slds-align_absolute-center">
                <div className="spaz-holyclip-logo-fg">
                  <a href="https://holyclip.net">
                    <img alt="logo" className="spaz-holyclip-logo" srcSet="/150.png 150w,/400.png 400w" sizes="(max-width: 48em) 150px,400px" src="/150.png"  />
                  </a>
                </div>
              </div>
              <SearchInput onFilterChanged={this.changedSearchFilter} onClear={this.searchResultsCleared} onSearchResults={this.searchResults} match={match} />
            </div>
          </div>
          <div ref={this.scrollableRef} onScroll={this.onscroll} className="spaz-scrollable slds-col slds-is-relative slds-grid slds-grid_align-center slds-scrollable_y">
            <div className="slds-col slds-is-absolute slds-size_11-of-12">
              <div>
                <div className="slds-grid slds-grid_align-space slds-p-around_x-small">
                  <Link className="slds-col slds-m-around_x-small" to="/about-holyclip">
                    <svg className="slds-icon_small slds-button__icon slds-button__icon_left" aria-hidden="true">
                      <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#info_alt"></use>
                    </svg>
                    About
                  </Link>
                  {/* {isAppleApp === true ?
                    ""
                    :
                    <Link target="_blank" className="slds-col slds-m-horizontal_small" to="/appstore">
                      <img alt="Download on the AppStore" src="/appstore.svg" type="image/svg+xml" />
                    </Link>
                  } */}
                  <Link target="_blank" className="slds-col slds-m-around_x-small" to="/twitter">
                    <svg className="slds-icon_small slds-button__icon slds-button__icon_left" xmlns="http://www.w3.org/2000/svg" viewBox="75 75 240 240">
                      <path style={{ fill: "#1da1f2" }} className="cls-2" d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23" />
                    </svg>
                    Follow
                    </Link>
                </div>
              </div>
              {this.state.results && this.state.results.length > 0 ?
                <ResultList ref={this.resultsRef} results={this.state.results} />
                : (this.state.results === undefined ? <PopularList seriesId={this.state.seriesId} videoId={this.state.videoId} onload={this.onscroll} refPassThru={this.resultsRef} /> : <div className="slds-text-heading_medium slds-align_absolute-center slds-p-top_large">There is no dialogue containing '{this.state.searchText}'</div>)}
            </div>
          </div>
        </div>
        <Route path={`${match.url}clip/:id`} component={DetailView} />
      </div>

    )
  }
}

export default HolyclipApp;