import React, { Component } from 'react';

// import fragmentShader from './glhelpers/GLFragmentShader'
// import vertexShader from './glhelpers/GLVertexShader'
// import m4 from './glhelpers/m4.js';
// import webglUtils from './glhelpers/webgl-utils.js';
// import primitives from './glhelpers/primitives.js';
// import physicsUtils from './glhelpers/physics-utils';
// import textureUtils from './glhelpers/texture-utils';

// function degToRad(d) {
//   return d * Math.PI / 180;
// }

// const fieldOfViewRadians = degToRad(50);
// const uniformsThatAreTheSameForAllObjects = {
//   u_lightWorldPos: [-50, 30, 100],
//   u_viewInverse: m4.identity(),
//   u_lightColor: [1, 1, 1, 1],
// };
// const uniformsThatAreComputedForEachObject = {
//   u_worldViewProjection: m4.identity(),
//   u_world: m4.identity(),
//   u_worldInverseTranspose: m4.identity(),
// };

class GLSpinner extends Component {

  // constructor(props) {
  //   super(props);
    // this.drawScene = this.drawScene.bind(this);
    // this.setCanvasRef = this.setCanvasRef.bind(this);
    // this.resize = this.resize.bind(this);
  // }


  // hsv2rgb(h, s, v) {
  //   let b, f, g, i, p, q, r, t, _ref, _ref1, _ref2, _ref3, _ref4, _ref5, _ref6;
  //   _ref = [h, s, v];
  //   h = _ref[0];
  //   s = _ref[1];
  //   v = _ref[2];
  //   v *= 255;
  //   if (s === 0) {
  //     r = g = b = v
  //   } else {
  //     if (h === 360) {
  //       h = 0
  //     }
  //     if (h > 360) {
  //       h -= 360
  //     }
  //     if (h < 0) {
  //       h += 360
  //     }
  //     h /= 60;
  //     i = Math.floor(h);
  //     f = h - i;
  //     p = v * (1 - s);
  //     q = v * (1 - s * f);
  //     t = v * (1 - s * (1 - f));
  //     switch (i) {
  //       default:
  //       case 0:
  //         _ref1 = [v, t, p];
  //         r = _ref1[0];
  //         g = _ref1[1];
  //         b = _ref1[2];
  //         break;
  //       case 1:
  //         _ref2 = [q, v, p];
  //         r = _ref2[0];
  //         g = _ref2[1];
  //         b = _ref2[2];
  //         break;
  //       case 2:
  //         _ref3 = [p, v, t];
  //         r = _ref3[0];
  //         g = _ref3[1];
  //         b = _ref3[2];
  //         break;
  //       case 3:
  //         _ref4 = [p, q, v];
  //         r = _ref4[0];
  //         g = _ref4[1];
  //         b = _ref4[2];
  //         break;
  //       case 4:
  //         _ref5 = [t, p, v];
  //         r = _ref5[0];
  //         g = _ref5[1];
  //         b = _ref5[2];
  //         break;
  //       case 5:
  //         _ref6 = [v, p, q];
  //         r = _ref6[0];
  //         g = _ref6[1];
  //         b = _ref6[2];
  //     }
  //   }
  //   r = Math.round(r);
  //   g = Math.round(g);
  //   b = Math.round(b);
  //   return [r, g, b]
  // }

  // rand(min, max) {
  //   if (max === undefined) {
  //     max = min;
  //     min = 0;
  //   }
  //   return min + Math.random() * (max - min);
  // }

  // randInt(range) {
  //   return Math.floor(Math.random() * range);
  // }

  // componentDidMount() {
  //   let canvas = this.canvas;
  //   if (canvas) {
  //     let gl = canvas.getContext("webgl");
  //     this.program = webglUtils.createProgramFromScripts(gl, [vertexShader, fragmentShader]);
  //     this.buffers = primitives.createSphereBuffers(gl, 10, 48, 24);
  //     this.uniformSetters = webglUtils.createUniformSetters(gl, this.program);
  //     this.attribSetters = webglUtils.createAttributeSetters(gl, this.program);
  //     this.uniformSetters = webglUtils.createUniformSetters(gl, this.program);
  //     this.textures = [
  //       // textureUtils.makeStripeTexture(gl, { color1: "#FFF", color2: "#CCC", }),
  //       textureUtils.makeCheckerTexture(gl, { color1: "#FFF", color2: "#CCC", }),
  //       // textureUtils.makeCircleTexture(gl, { color1: "#FFF", color2: "#CCC", }),
  //     ];
  //     let objects = [];
  //     let numObjects = 21;
  //     let baseColor = this.rand(240);
  //     for (let ii = 0; ii < numObjects; ++ii) {
  //       let rgb = this.hsv2rgb(this.rand(baseColor, baseColor + 120), 0.5, 1);
  //       objects.push({
  //         point: physicsUtils.pointMassAt(),
  //         xRotation: Math.random() * degToRad(360),
  //         yRotation: Math.random() * degToRad(360),
  //         zRotation: Math.random() * degToRad(360),
  //         materialUniforms: {
  //           u_colorMult: [rgb[0] / 255, rgb[1] / 255, rgb[2] / 255, 1],
  //           u_diffuse: this.textures[this.randInt(this.textures.length)],
  //           u_specular: [1, 1, 1, 1],
  //           u_shininess: 0.1,
  //           u_specularFactor: 0.1,
  //         },
  //       });
  //     }
  //     this.objects = objects;
  //     gl.canvas.onclick = function () {
  //       var v = parseInt(Math.random() * objects.length);
  //       objects[v].point.prev.x += Math.random() * 12 - 6;
  //       objects[v].point.prev.y += Math.random() * 12 - 6;
  //       objects[v].point.prev.z += Math.random() * 12 - 6;
  //     };
  //     // requestAnimationFrame(this.drawScene);
  //     this.randomBumpEvent();
  //   }
  // }

  // randomBumpEvent() {
  //   let that = this;
  //   setTimeout(() => {
  //     if (that.canvas) {
  //       that.randomBumpEvent();
  //       that.canvas.onclick();
  //     }
  //   }, that.rand(500, 1500));
  // }

  // resize() {
  //   if (this.canvas) {
  //     var gl = this.canvas.getContext("webgl");
  //     var realToCSSPixels = window.devicePixelRatio;
  //     // Lookup the size the browser is displaying the canvas in CSS pixels
  //     // and compute a size needed to make our drawingbuffer match it in
  //     // device pixels.
  //     var displayWidth = Math.floor(gl.canvas.clientWidth * realToCSSPixels);
  //     var displayHeight = Math.floor(gl.canvas.clientHeight * realToCSSPixels);
  //     // Check if the canvas is not the same size.
  //     if (gl.canvas.width !== displayWidth ||
  //       gl.canvas.height !== displayHeight) {
  //       // Make the canvas the same size
  //       gl.canvas.width = displayWidth;
  //       gl.canvas.height = displayHeight;
  //     }
  //   }
  // }

  // drawScene() {
  //   if (!this.canvas) {
  //     return;
  //   }
  //   let canvas = this.canvas;
  //   var gl = canvas.getContext("webgl");
  //   if (!gl) {
  //     return;
  //   }
  //   // If duration since last drawScene was called is greater than 100ms and not loaded yet
  //   let nowTicks = (new Date()).valueOf();
  //   let ticksPerFrame = nowTicks - (this.lastDrawDate || nowTicks);
  //   this.lastDrawDate = nowTicks;
  //   if (!this.loaded) {
  //     if (ticksPerFrame > 30 || ticksPerFrame === 0) {
  //       // Wait to draw when cpu is more available so as to appear less laggy
  //       requestAnimationFrame(this.drawScene);
  //       return;
  //     }
  //   }
  //   this.loaded = true;
  //   this.resize();

  //   // Tell WebGL how to convert from clip space to pixels
  //   gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  //   // Clear the canvas AND the depth buffer.
  //   gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
  //   gl.enable(gl.CULL_FACE);
  //   gl.enable(gl.DEPTH_TEST);
  //   // Compute the projection matrix
  //   var aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
  //   var projectionMatrix = m4.perspective(fieldOfViewRadians, aspect, 1, 2000);

  //   // Compute the camera's matrix using look at.
  //   var cameraPosition = [75, 0, -25];
  //   var target = [25, 25, 25];
  //   var up = [0, 1, 0];
  //   var cameraMatrix = m4.lookAt(cameraPosition, target, up, uniformsThatAreTheSameForAllObjects.u_viewInverse);

  //   // Make a view matrix from the camera matrix.
  //   var viewMatrix = m4.inverse(cameraMatrix);
  //   var viewProjectionMatrix = m4.multiply(projectionMatrix, viewMatrix);

  //   gl.useProgram(this.program);

  //   // Setup all the needed attributes.
  //   const attribs = {
  //     a_position: { buffer: this.buffers.position, numComponents: 3, },
  //     a_normal: { buffer: this.buffers.normal, numComponents: 3, },
  //     a_texcoord: { buffer: this.buffers.texcoord, numComponents: 2, },
  //   };
  //   webglUtils.setAttributes(this.attribSetters, attribs);

  //   // Bind the indices.
  //   gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this.buffers.indices);

  //   // Set the uniforms that are the same for all objects.
  //   webglUtils.setUniforms(this.uniformSetters, uniformsThatAreTheSameForAllObjects);

  //   // Draw objects
  //   let that = this;
  //   this.objects.forEach(function (object) {
  //     var point = object.point;
  //     // Make all objects collide by adding constraints between them
  //     // Put inside a container
  //     point.clearConstraints();
  //     point.addConstraint(physicsUtils.pointAt(25, 25, 25), 19);
  //     that.objects.forEach(y => {
  //       if (point !== y.point) {
  //         point.addConstraint(y.point, undefined, 15);
  //       }
  //     });
  //     point.tick(1);

  //     // Compute a position for this object based on the time.
  //     var worldMatrix = m4.yRotation(0);
  //     worldMatrix = m4.translate(worldMatrix, object.point.x, object.point.y, object.point.z);
  //     var p = physicsUtils.pointAt(object.point.x, object.point.y, object.point.z);
  //     p.subtract(object.point.prev);
  //     object.xRotation += p.z / (Math.PI * 2);
  //     object.yRotation += p.y / (Math.PI * 2);
  //     object.zRotation += p.x / (Math.PI * 2);

  //     worldMatrix = m4.xRotate(worldMatrix, object.xRotation);
  //     worldMatrix = m4.zRotate(worldMatrix, object.zRotation);
  //     worldMatrix = m4.yRotate(worldMatrix, object.zRotation);
  //     uniformsThatAreComputedForEachObject.u_world = worldMatrix;

  //     // Multiply the matrices.
  //     m4.multiply(viewProjectionMatrix, worldMatrix, uniformsThatAreComputedForEachObject.u_worldViewProjection);
  //     m4.transpose(m4.inverse(worldMatrix), uniformsThatAreComputedForEachObject.u_worldInverseTranspose);
  //     // Set the uniforms we just computed
  //     webglUtils.setUniforms(that.uniformSetters, uniformsThatAreComputedForEachObject);
  //     // Set the uniforms that are specific to the this object.
  //     webglUtils.setUniforms(that.uniformSetters, object.materialUniforms);
  //     // Draw the geometry.
  //     gl.drawElements(gl.TRIANGLES, that.buffers.numElements, gl.UNSIGNED_SHORT, 0);
  //   });
  //   requestAnimationFrame(this.drawScene);
  // }

  // componentWillUnmount() {
  //   // Release resources
  //   if (this.canvas) {
  //     let canvas = this.canvas;
  //     var gl = canvas.getContext("webgl");
  //     var numTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
  //     for (var unit = 0; unit < numTextureUnits; ++unit) {
  //       gl.activeTexture(gl.TEXTURE0 + unit);
  //       gl.bindTexture(gl.TEXTURE_2D, null);
  //       gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
  //     }
  //     gl.bindBuffer(gl.ARRAY_BUFFER, null);
  //     gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
  //     gl.bindRenderbuffer(gl.RENDERBUFFER, null);
  //     gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  //     let buffer;
  //     for (buffer in this.buffers) {
  //       if (this.buffers[buffer] instanceof WebGLBuffer) {
  //         gl.deleteBuffer(this.buffers[buffer]);
  //       }
  //     }
  //     gl.deleteProgram(this.program);
  //     delete this.canvas;
  //   }
  // }

  // setCanvasRef(canvas) {
  //   if (canvas) this.canvas = canvas;
  // }


  render() {
    return (
      <span className="spaz-gl-spinner">
        <div className="spinner"></div>
        {/* <canvas ref={this.setCanvasRef}></canvas> */}
      </span>
    );
  }
}

export default GLSpinner;