import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './About.css';

class About extends Component {
  render() {
    return (
      <div class="spaz-about">
        <h1 class="slds-p-top_x-large slds-text-heading_large"><Link to="/"><img alt="logo" class="spaz-holyclip-logo" src="/holyclip-logo.svg" type="image/svg+xml" /></Link></h1>
        <h2 class="slds-p-bottom_x-large slds-text-heading_medium">How it works</h2>
        <section class="slds-p-around_large slds-grid slds-wrap spaz-step-0 slds-align_absolute-center">
          <figure class="slds-small-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2">
            <video playsInline="true" webkit-playsinline="true" autoPlay="true" loop="true">
              <source src="./slack-share.mp4" type="video/mp4" />
            </video>
          </figure>
          <div class="slds-align_absolute-center slds-p-around_large slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2">
            <div class="slds-size_3-of-4">
              <h1 class="slds-text-heading_large">Has reference</h1>
              <h1 class="slds-text-heading_large slds-p-bottom_small">cannot find clip.</h1>
              <h2>Holy Clip indexes video subtitles to quickly locate, clip and customize a scene. Clips can be shared on social media and in text messages by pasting clip URL.</h2>
            </div>
          </div>
        </section>
        <section class="slds-p-around_large slds-grid slds-wrap spaz-step-05 slds-align_absolute-center">
          <div class="slds-align_absolute-center slds-p-around_large slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2">
            <div class="slds-size_3-of-4">
              <h1 class="slds-text-heading_large slds-p-bottom_small">iOS Messages app</h1>
              <h2>The <Link target="_blank" to="/appstore">Holy Clip iOS app</Link> enables sharing clips with audio directly from within Messages</h2>
              <Link target="_blank" to="/appstore">
                <img className="slds-p-around_small" alt="appstore" src="/appstore.svg" type="image/svg+xml" />
              </Link>
            </div>
          </div>
          <figure class="slds-small-size_1-of-1 slds-medium-size_1-of-1 slds-large-size_1-of-2">
            <video playsInline="true" webkit-playsinline="true" autoPlay="true" loop="true">
              <source src="./messages.mp4" type="video/mp4" />
            </video>
          </figure>
        </section>
        <section class="slds-p-around_large slds-grid slds-wrap spaz-step-1">
          <figure class="slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2">
            <img alt="screenshot" src="/handbrake.png" />
          </figure>
          <div class="slds-align_absolute-center slds-p-around_large slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2">
            <div class="slds-size_3-of-4">
              <h1 class="slds-text-heading_large slds-p-bottom_small">Prepare your media</h1>
              <h2>Use free tools like <a target="new" href="https://handbrake.fr/">HandBrake</a> to convert your media to mp4 format with embedded subtitles.</h2>
            </div>
          </div>
        </section>
        <section class="spaz-step spaz-step-2 slds-p-around_large slds-grid slds-wrap">
          <div class="slds-align_absolute-center slds-p-around_large slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2">
            <div class="slds-size_3-of-4">
              <h1 class="slds-text-heading_large slds-p-bottom_small">Upload media</h1>
              <h2>Use Holy Clip customer portal (coming soon) to create an <a target="new" href="https://aws.amazon.com/s3/">Amazon S3</a> bucket to bulk upload your media. Our platform will convert the media and index the subtitles automatically.</h2>
            </div>
          </div>
          <figure class="slds-p-around_large slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2">
            <img alt="screenshot" src="/upload.png" />
          </figure>
        </section>
        <section class="slds-grid slds-grid_align-center spaz-step spaz-step-3">
          <div class="slds-is-absolute slds-p-around_small slds-grid slds-grid_align-center">
            <div class="slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_3-of-4">
              <h1 class="slds-text-heading_large slds-p-top_small slds-p-bottom_small">Go viral</h1>
              <h2 class="slds-p-around_small">Delight your audience with custom clips of that precise scene that they've been searching for. Shareable on all major social media, messaging services, and promoted by the Holy Clip platform.</h2>
            </div>
          </div>
          <img alt="" src="/social.jpg" />
        </section>
        <section class="spaz-step spaz-step-2 slds-p-around_large slds-grid slds-wrap">

          <div class="slds-align_absolute-center slds-p-around_large slds-small-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-2">
            <div>

              <h1 class="slds-text-heading_large">            
              <svg class="slds-icon slds-icon-text-default slds-m-right_small" aria-hidden="true">
                  <use xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#world" />
              </svg>
              Contact Holy Clip</h1>
              <form method="post" action="https://u6i8agbig0.execute-api.us-east-1.amazonaws.com/prod/mail">
                <div className="slds-container_large slds-align_absolute-center slds-p-around_large">
                  <div className="slds-col slds-size_1-of-1 slds-grid slds-wrap slds-grid_vertical-align-start">
                    <div className="slds-col slds-size_1-of-1">
                      <div className="slds-form-element">
                        <label className="slds-form-element__label" htmlFor="form-element-01">Name</label>
                        <div className="slds-form-element__control">
                          <input name="name" type="text" id="form-element-01" className="slds-input" placeholder="Your Name" />
                        </div>
                      </div>
                      <div className="slds-form-element">
                        <label className="slds-form-element__label" htmlFor="form-element-01">Email</label>
                        <div className="slds-form-element__control">
                          <input name="email" type="text" id="form-element-01" className="slds-input" placeholder="Your Email" />
                        </div>
                      </div>
                      <div className="slds-form-element">
                        <label className="slds-form-element__label" htmlFor="select-01">Subject</label>
                        <div className="slds-form-element__control">
                          <div className="slds-select_container">
                            <select name="subject" className="slds-select" id="select-01">
                              <option value="Support">Customer Portal Beta Program</option>
                              <option value="Org Chart">Partnership</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slds-col slds-size_1-of-1 slds-publisher slds-is-active">
                      <label htmlFor="comment-text-input2" className="slds-form-element__label">
                        <span className="slds-assistive-text">Body</span>Body</label>
                      <textarea rows="5" name="body" className="slds-publisher__input slds-textarea slds-text-longform" placeholder="Body text..."></textarea>
                      <div className="slds-publisher__actions slds-grid slds-grid_align-end">
                        <input type="submit" value="Send" className="slds-button slds-button_brand slds-m-vertical_small" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </section>
      </div >
    )
  }
}

export default About;