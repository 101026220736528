import React, { Component } from 'react';

class RadioButtonGroup extends Component {

  constructor(props) {
    super(props);
    this.changed = this.changed.bind(this);
    this.state = {
      selected: undefined
    }
  }

  changed(e) {
    let selected = e.target.value || e.target.dataset.value;
    this.setState({
      selected: selected
    });
    if (typeof (this.props.onChange) === "function") {
      this.props.onChange(e, selected);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.setState({
        selected: this.props.selected
      });
    }
  }

  render() {
    let buttons = [];
    if (this.props.buttons && this.props.buttons.length > 0) {
      let that = this;
      let selected = this.props.selected;
      this.props.buttons.forEach(function (b, i) {
        buttons.push(
          <span key={i} className="slds-button slds-radio_button" onClick={that.changed} data-value={b.value}>
            <input checked={selected === b.value} value={b.value} type="radio" onChange={that.changed} />
            <label className="slds-radio_button__label" htmlFor={b.value} data-value={b.value} title={b.label}>
              <span className="slds-radio_faux" data-value={b.value}>
                {typeof (b.iconName) === "string" ?
                  <React.Fragment>
                    <svg className="slds-button__icon" aria-hidden="true">
                      <use xlinkHref={`/assets/icons/utility-sprite/svg/symbols.svg#${b.iconName}`}></use>
                    </svg>
                    <span className="slds-assistive-text">{b.label}</span>
                  </React.Fragment>
                  :
                  b.label
                }
              </span>
            </label>
          </span >);
      });
    }

    return (
      <div className="spaz-radio-button-group" role="status">
        <fieldset className="slds-form-element">
          <legend className="slds-form-element__legend slds-form-element__label">{this.props.label}</legend>
          <div className="slds-form-element__control">
            <div className="slds-radio_button-group">
              {buttons}
            </div>
          </div>
        </fieldset>
      </div>
    )
  }
}

export default RadioButtonGroup;