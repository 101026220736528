import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ImageResult from './ImageResult';
import APP_CONST from './HolyclipAppConst'
import './ResultList.css';

let recordHeight = APP_CONST.RECORD_HEIGHT;

class ResultList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      renderStart: 0,
      renderEnd: 0
    };
    this.ref = React.createRef();
    this.onscroll = this.onscroll.bind(this);
  }

  onscroll(scroll, rows) {
    const cols = this.getCols();
    // const rows = this.getRows();
    if (this.props.results && this.props.results.length > 0) {
      // This is what is visible
      let visibleStart = Math.floor(scroll / recordHeight) * cols;
      let visibleEnd = Math.min(visibleStart + rows * cols, this.props.results.length);
      // This is rendered additionally for UX
      let renderStart = Math.max(0, visibleStart - rows * cols);
      let renderEnd = Math.min(visibleEnd, this.props.results.length);
      // Check if new visible start is out of boundary of prior display range
      let needsUpdate = visibleStart <= this.state.renderStart || visibleEnd >= this.state.renderEnd;
      if (needsUpdate) {
        this.setState({
          renderEnd: renderEnd,
          renderStart: renderStart
        });
      }
    }
  }

  getCols() {
    let cols = 0;
    if (this.ref.current) {
      let w = this.ref.current.clientWidth;
      cols = parseInt(w / recordHeight, 10);
    }
    return cols;
  }

  shouldComponentUpdate() {
    // Only update when user scrolls outside loaded images
    if (!this.checkedThumbSize) {
      // Make sure recordHeight is set to what css renders
      // this is for iOS which reduces the thumb size
      let imgElement = document.getElementsByClassName("spaz-gif");
      if (imgElement && imgElement.length > 0) {
        recordHeight = imgElement[0].clientHeight;
        this.checkThumbSize = true;
      }
    }
    return true;
  }

  render() {
    let results = [];
    const cols = this.getCols();
    for (let i = this.state.renderStart; i < this.state.renderEnd; ++i) {
      let r = this.props.results[i];
      if (r) {
        let url = `/clip/${r.GUID}`;
        let search = "";
        if (r.url && r.url.indexOf("?") > -1) {
          search = r.url.split("?")[1];
        }
        r.Text = APP_CONST.stripHtml(r.Text);
        results.push(<Link key={r.GUID} to={{
          pathname: url,
          search: search,
          state: {
            caption: r.Text
          }
        }}><ImageResult id={r.GUID} title={r.Text} url={APP_CONST.API_ENDPOINT + "/gif/" + encodeURIComponent(r.GUID) + "?thumb=1"} /></Link>);
      }
    }
    return (
      <div ref={this.ref} className="spaz-result-list spaz-scroll slds-p-bottom_xx-large" onScroll={this.onscroll}>
        <div style={{ height: parseInt((this.state.renderStart / cols) * recordHeight, 10) || 0 }}></div>
        {results}
        <div style={{ height: parseInt((this.props.results.length - this.state.renderEnd) * recordHeight / cols, 10) || 0 }}></div>
      </div>
    )
  }
}

export default ResultList;