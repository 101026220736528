import React, { Component } from 'react';
import GLSpinner from './GLSpinner';
import './VideoResult.css'

class VideoResult extends Component {

  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.toggleMute = this.toggleMute.bind(this);
    this.loadedComplete = this.loadedComplete.bind(this);
    this.delayAutoMute = this.delayAutoMute.bind(this);
    this.state = {
      isMuted: true,
      loading: true
    };
    if (this.props.title) {
      window.document.title = this.props.title;
    }
  }

  delayAutoMute() {
    clearTimeout(this.muteTimer);
    // Auto mute after delay
    let that = this;
    this.muteTimer = setTimeout(() => {
      if (that.videoRef && that.videoRef.current) {
        that.setState({
          isMuted: true
        });
      }
    }, 15000);
  }

  toggleMute() {
    if (this.state.isMuted) {
      this.delayAutoMute();
      if (window.gtag) {
        window.gtag("event", "video_start", {
          url: this.props.url
        });
      }
    }
    this.setState({
      isMuted: !this.state.isMuted
    });
  }

  loadedComplete() {
    if (this.state.loading) {
      if (!this.state.isMuted) {
        this.delayAutoMute();
      }
      this.setState({
        loading: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.url !== prevProps.url) {
      if (this.videoRef && this.videoRef.current) {
        this.videoRef.current.load();
      }
      clearTimeout(this.muteTimer);
      this.setState({
        loading: true
      });
    }
  }

  render() {
    // const GIF_URL = this.props.url ? this.props.url.replace(APP_CONST.API_ENDPOINT + APP_CONST.MP4_ENDPOINT, APP_CONST.API_ENDPOINT + APP_CONST.GIF_ENDPOINT) : "";
    return (
      <span className="spaz-video">
        {this.state.loading ?
          <GLSpinner />
          :
          ""
        }
        <video ref={this.videoRef} onLoadedData={this.loadedComplete} playsInline={true} webkit-playsinline="true" autoPlay={true} loop={true} muted={this.state.isMuted}>
          <source src={this.props.url} type="video/mp4" />
          {/* Fallback to gif if html video not supported */}
          {/* <img alt={this.props.title} src={GIF_URL} /> */}
        </video>
        <button onClick={this.toggleMute} className="spaz-button-mute slds-button slds-button_icon">
          <svg className="slds-button__icon" aria-hidden="true">
            {this.state.isMuted ?
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#volume_off" />
              :
              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="/assets/icons/utility-sprite/svg/symbols.svg#volume_high" />
            }
          </svg>
          <span className="slds-assistive-text">Mute</span>
        </button>
      </span>
    )
  }
}

export default VideoResult;